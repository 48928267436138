import React, { useEffect, useState } from "react";
import { Modal } from "@material-ui/core";
import { Button, Form } from "react-bootstrap";
import {
  MDBCard,
  MDBCardBody,
  MDBCardTitle,
  MDBCardText,
} from "mdb-react-ui-kit";
import { Network } from "../../../config/axios";
import {
  adminCovertPartnersMeeting,
  adminUpdatePrice,
} from "../../../config/service";
import { toast } from "react-toastify";
import DatePicker from "react-datepicker";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { ageCalculator } from "../../DoctorDashboard/DoctorFuture";
import {
  checkEmailFormate,
  handleCopyToClipboard,
} from "../../../utils/function";
import AddParticipantModal from "./AddParticipantModal";
import DyteMeetingLink from "../../common/DyteMeetLink";

const MeetingUpdate = (props) => {
  const {
    consultData,
    updateId,
    updateData,
    handleClose,
    updateModal,
    setPriceModal,
    priceModal,
    doctorId,
    setDoctorId,
    refetchAllmeetings,
    setUpdateData,
    allMeetingData,
  } = props;
  const { t } = useTranslation();
  const token = localStorage.getItem("token");
  const [doctorList, setDoctorList] = useState([]);
  const [requestDescription, setRequestDescription] = useState();
  const [updatedDoctorId, setUpdatedDoctorId] = useState();
  const [patientGender, setPatientGender] = useState();
  const [first_name, setFirst_name] = useState();
  const [last_name, setLast_name] = useState();
  const [patientEmail, setPatientEmail] = useState();
  const [patientId, setPatientId] = useState();
  const [personalId, setPersonalId] = useState();
  const [consult, setConsult] = useState();
  const [UpdatePrice, setUpdatePrice] = useState();
  const [dateChange, setDateChange] = useState();
  const [dateChange1, setDateChange1] = useState();
  const [dobchange, setDOBChange] = useState();
  const [participantModal, setParticipantModal] = useState(false);
  const navigate = useNavigate();
  const lang = localStorage.getItem("lang");

  useEffect(() => {
    setUpdatedDoctorId();
    setDateChange();
    setDateChange1();
  }, [handleClose]);

  const consultChange = (e) => {
    if (updateData?.consultation?._id !== e.target.value) {
      const findData = consultData.filter((d) => {
        return d.eng === e.target.value;
      });
      setConsult(findData[0]._id);
      alldoctorList(findData[0]._id);
      setDoctorId("");
      setUpdatedDoctorId("");
    }
  };
  const alldoctorList = async (data) => {
    try {
      const response = await Network().get(
        "/admin/getDoctorsByConsultationId/" + data,
        {
          headers: {
            authorization: token,
            "Content-Type": "application/json",
          },
        }
      );
      setDoctorList(response.data.doctors);
    } catch (error) {
      if (error.response.status === 401) {
        localStorage.removeItem("token");
        navigate("/login");
      }
      console.log(error);
    }
  };

  const handlePriceUpdate = () => {
    setPriceModal(true);
  };
  const handlePriceChange = async () => {
    let data = {
      meetingId: updateId,
      meetingPrice: UpdatePrice,
    };
    const response = await adminUpdatePrice(data);
    toast.success(t(response.data.msg));
    props.priceClose();
    handleClose();
  };
  const handleOfferNonIchilovDoctor = async () => {
    try {
      const response = await Network().get(
        `/admin/offerNonIchilovDoctor/${updateId}`,
        {
          headers: {
            authorization: token,
          },
        }
      );
      const { code, msg } = response.data;
      if (code === 200) {
        toast.success(t(msg));
        handleClose();
      }
    } catch (error) {
      console.log(error);
    }
  };
  const handleConvertHidocMeeting = async () => {
    const data = {
      meetingId: updateId,
    };
    try {
      const response = await adminCovertPartnersMeeting(data);
      const { code, msg } = response.data;
      if (code === 200) {
        toast.success(t(msg));
        handleClose();
      }
    } catch (error) {
      console.log(error);
    }
  };
  const UpdateDetails = async () => {
    const data = {
      meetingIds: [updateId],
      consultingFieldId: consult,
      requestDescription: requestDescription,
      scheduledDate: dateChange,
      duePaymentDate: dateChange1,
      meetingPatientDetails: {
        first_name: first_name,
        last_name: last_name,
        patientEmail: patientEmail,
        patientDOB: dobchange,
        patientId: patientId,
        patientGender: patientGender,
        personalId: personalId,
      },
    };
    if (!updatedDoctorId && !doctorId) {
      data.DoctorId = "";
    } else if (updatedDoctorId !== doctorId) {
      data.DoctorId = updatedDoctorId;
    }
    if (data?.meetingPatientDetails?.patientEmail) {
      const isEmailCorrect = checkEmailFormate(
        data?.meetingPatientDetails?.patientEmail
      );
      if (!isEmailCorrect) {
        toast.error("Please enter valid email");
        return;
      }
    }

    Object.keys(data).forEach((key) => {
      if (data[key] === undefined) {
        delete data[key];
      }
      Object.keys(data.meetingPatientDetails).forEach((key) => {
        if (data.meetingPatientDetails[key] === undefined) {
          delete data.meetingPatientDetails[key];
        }
      });
    });
    if (Object.keys(data.meetingPatientDetails).length == 0) {
      delete data["meetingPatientDetails"];
    }
    try {
      const response = await Network().post(
        "/admin/updateAppointmentById",
        data,
        {
          headers: {
            authorization: token,
          },
        }
      );
      if ([200, 201, 202].includes(response.status)) {
        toast.success(t(response.data.message));
        handleClose();
      }
    } catch (error) {
      console.log(error);
      if (error?.response?.status === 424) {
        toast.error(t(error?.response?.data?.message));
      }
    }
  };
  useEffect(() => {
    if (updateData?.consultation?._id) {
      alldoctorList(updateData?.consultation?._id);
    }
  }, [updateData?.consultation?._id]);
  // const showEror = () => {
  //   toast.error(t("Please select doctor"));
  // };

  const handleAddParticipant = () => {
    setParticipantModal(true);
  };
  const handleCloseAddParticipant = () => {
    setParticipantModal(false);
  };

  return (
    <div>
      <Modal
        open={updateModal}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="summaryModal admin-meeting-update"
      >
        <MDBCard className="meetingUpdate_card updatemodal">
          <MDBCardBody>
            <MDBCardTitle className="headerText">
              {t("Meeting Details")}
            </MDBCardTitle>
            {updateData?.clientData?._id && updateData?.consultation?.eng && (
              <Form className="form">
                <Form.Group className="mb-3">
                  <h3> {t("Consulting field")}</h3>
                  <select
                    className="form-control"
                    name="consultingFieldName"
                    onChange={consultChange}
                    id="consultingFieldName"
                    defaultValue={
                      updateData?.consultation?.eng
                        ? updateData?.consultation?.eng
                        : ""
                    }
                  >
                    {consultData.map((d, i) => {
                      return (
                        <option value={d.eng} key={i}>
                          {lang === "EN" ? d?.eng : d?.heb}
                        </option>
                      );
                    })}
                  </select>
                </Form.Group>

                <Form.Group className="mb-3">
                  <h3> {t("Doctor List")}</h3>

                  <select
                    className="form-control"
                    onChange={(e) => {
                      setUpdatedDoctorId(e.target.value);
                    }}
                    id="doctor_list"
                    value={
                      updatedDoctorId
                        ? updatedDoctorId
                        : doctorId
                        ? doctorId
                        : t("Select doctor")
                    }
                  >
                    <option value="">{t("Select doctor")}</option>
                    {doctorList.map((d, i) => {
                      return (
                        <option
                          key={i}
                          value={`${d?.doctorId}`}
                          selected={doctorId === d?.doctorId}
                        >{`${d?.doctorData?.first_name} ${d?.doctorData?.last_name}`}</option>
                      );
                    })}
                  </select>
                </Form.Group>

                <Form.Group className="mb-3">
                  <h3> {t("Client Name")}</h3>
                  <input
                    className="form-control"
                    name="clientId"
                    id="clientId"
                    readOnly={true}
                    defaultValue={
                      updateData.length != 0
                        ? `${updateData?.clientData?.first_name} ${updateData?.clientData?.last_name}`
                        : ""
                    }
                  />
                </Form.Group>
                <Form.Group className="mb-3">
                  <h3>{t("Client Id")}</h3>
                  <input
                    className="form-control"
                    name="clientId"
                    type="text"
                    readOnly={true}
                    id="cid"
                    defaultValue={
                      updateData.length != 0 ? updateData.clientData?.id : ""
                    }
                  />
                </Form.Group>
                <Form.Group className="mb-3">
                  <h3> {t("Request Description")}</h3>
                  <input
                    className="form-control"
                    name="requestDescription"
                    id="requestDescription"
                    onChange={(e) => setRequestDescription(e.target.value)}
                    defaultValue={
                      updateData.length != 0
                        ? updateData.requestDescription
                        : ""
                    }
                  />
                </Form.Group>
                <Form.Group className="mb-3">
                  <h3>{t("Patient First Name")}</h3>
                  <input
                    className="form-control"
                    name="first_name"
                    type="text"
                    onChange={(e) => setFirst_name(e.target.value)}
                    id="firstname"
                    defaultValue={
                      updateData.length != 0
                        ? updateData.meetingpatientdetails?.first_name
                        : ""
                    }
                  />
                </Form.Group>
                <Form.Group className="form-group mb-3">
                  <h3>{t("Patient Last Name")} </h3>
                  <input
                    className="form-control"
                    name="last_name"
                    type="text"
                    onChange={(e) => setLast_name(e.target.value)}
                    id="lastname"
                    defaultValue={
                      updateData.length != 0
                        ? updateData.meetingpatientdetails?.last_name
                        : ""
                    }
                  />
                </Form.Group>
                <Form.Group className="mb-3">
                  <h3> {t("Patient Age")}</h3>
                  <input
                    className="form-control"
                    name="patientAge"
                    id="patientAge"
                    readOnly={true}
                    value={
                      updateData.length != 0
                        ? ageCalculator(
                            updateData.meetingpatientdetails?.patientDOB
                          )
                        : ""
                    }
                  />
                </Form.Group>
                <Form.Group className="mb-3">
                  <h3>{t("Patient Id")}</h3>
                  <input
                    className="form-control"
                    name="patientId"
                    type="text"
                    onChange={(e) => setPatientId(e.target.value)}
                    id="pid"
                    defaultValue={
                      updateData.length != 0
                        ? updateData.meetingpatientdetails?.patientId
                        : ""
                    }
                  />
                </Form.Group>
                <Form.Group className="form-group mb-3">
                  <h3>{t("Patient Email")} </h3>
                  <input
                    className="form-control forceLtrInput"
                    name="patientEmail"
                    type="text"
                    onChange={(e) => setPatientEmail(e.target.value)}
                    id="email"
                    defaultValue={
                      updateData.length != 0
                        ? updateData.meetingpatientdetails?.patientEmail
                        : ""
                    }
                  />
                </Form.Group>
                {updateData?.clientData?.personalId ? (
                  <Form.Group className="mb-3">
                    <h3>{t("Personal ID Number")}</h3>
                    <input
                      className="form-control"
                      readOnly={true}
                      name="personalId"
                      type="text"
                      onChange={(e) => setPersonalId(e.target.value)}
                      id="personalId"
                      defaultValue={
                        updateData.length != 0
                          ? updateData?.clientData?.personalId
                          : ""
                      }
                    />
                  </Form.Group>
                ) : null}
                <Form.Group className="mb-3" style={{ margin: "0 -48%" }}>
                  <h3>{t("Patient Date of Birth")}</h3>
                  <DatePicker
                    showYearDropdown
                    onChange={(date) => setDOBChange(date)}
                    selected={
                      dobchange
                        ? dobchange
                        : new Date(
                            updateData.length != 0
                              ? updateData?.meetingpatientdetails?.patientDOB ||
                                new Date()
                              : ""
                          )
                    }
                    className="datePicker"
                    dateFormat="dd-MM-yyyy"
                  />
                </Form.Group>

                <Form.Group className="form-group mb-3">
                  <h3>{t("Patient Gender")} </h3>
                  <select
                    className="form-control text"
                    name="patientGender"
                    onChange={(e) => setPatientGender(e.target.value)}
                    defaultValue={
                      updateData.length != 0
                        ? updateData.meetingpatientdetails?.patientGender
                        : ""
                    }
                  >
                    <option>{t("Select")}</option>
                    <option value="Male">{t("Male")}</option>
                    <option value="Female">{t("Female")}</option>
                  </select>
                </Form.Group>
                <Form.Group className="mb-3" style={{ margin: "0 -48%" }}>
                  <h3>{t("Schedule Date")}</h3>

                  <DatePicker
                    onChange={(date) => setDateChange(date)}
                    selected={
                      dateChange
                        ? dateChange
                        : new Date(
                            updateData.length != 0
                              ? updateData?.scheduledDate || new Date()
                              : ""
                          )
                    }
                    className="datePicker"
                    dateFormat="dd-MM-yyyy HH:mm"
                    showTimeSelect
                    showYearDropdown
                  />
                </Form.Group>
                {updateData.statuses == "Scheduled" ? (
                  <>
                    <Form.Group className="mb-3" style={{ margin: "0 -48%" }}>
                      <h3>{t("Due Payment Date")}</h3>
                      {updateData.length != 0 && updateData?.duePaymentDate && (
                        <DatePicker
                          onChange={(date) => setDateChange1(date)}
                          selected={
                            dateChange1
                              ? dateChange1
                              : new Date(
                                  updateData.length != 0
                                    ? updateData.duePaymentDate
                                    : ""
                                )
                          }
                          className="datePicker"
                          dateFormat="dd-MM-yyyy HH:mm"
                          showTimeSelect
                          showYearDropdown
                        />
                      )}
                    </Form.Group>
                    {updateData?.dyteMeetingResponse ? (
                      <DyteMeetingLink updateData={updateData} />
                    ) : updateData?.meetingLinkDoctor &&
                      updateData.meetingLink ? (
                      <Form.Group className="mb-3" style={{ margin: "0 -48%" }}>
                        <h3>{t("Zoom Details")}</h3>
                        <h2>
                          <a href={updateData.meetingLinkDoctor}>Doctor</a>{" "}
                          |&nbsp;
                          <a href={updateData.meetingLink}>Client</a>
                          <div className="password-grid">
                            <Button
                              onClick={() => {
                                handleCopyToClipboard(
                                  updateData?.meetingPassword,
                                  "zoom"
                                );
                              }}
                              className="copy-btn"
                            >
                              Copy <i class="las la-copy"></i>
                            </Button>
                            Password: {updateData.meetingPassword}{" "}
                          </div>
                        </h2>
                      </Form.Group>
                    ) : (
                      ""
                    )}
                  </>
                ) : null}

                {/* {updateData?.survey && (
                  <>
                    <h1> {t("Medical questionnaire")}</h1>
                    <Form.Group>
                      <h3> {t("Main Complaint")}</h3>
                      <input
                        className="form-control"
                        name="mainComplaint"
                        id="mainComplaint"
                        onChange={handleChange}
                        defaultValue={
                          updateData.length != 0
                            ? updateData?.survey.mainComplaint
                            : ""
                        }
                      />
                    </Form.Group>
                    <Form.Group>
                      <h3> {t("Medical History")}</h3>
                      <input
                        className="form-control"
                        name="medicalHistory"
                        id="medicalHistory"
                        onChange={handleChange}
                        defaultValue={
                          updateData.length != 0
                            ? updateData?.survey.medicalHistory
                            : ""
                        }
                      />
                    </Form.Group>
                    <Form.Group>
                      <h3> {t("Medications")}</h3>
                      <input
                        className="form-control"
                        name="medications"
                        id="medications"
                        onChange={handleChange}
                        defaultValue={
                          updateData.length != 0
                            ? updateData?.survey.medications
                            : ""
                        }
                      />
                    </Form.Group>
                  </>
                )}
                <div style={{ margin: "10px auto" }}>
                  <Button size="lg" onClick={getSummary}>
                    {" "}
                    {t("View Prescription/Summary and Update")}
                  </Button>
                </div> */}
                <br></br>

                <div style={{ margin: "10px auto" }}>
                  <Button size="lg" onClick={handlePriceUpdate}>
                    {" "}
                    {t("Update Meeting Price")}
                  </Button>
                </div>
                {updateData?.statuses === "Created" &&
                updateData?.partnerId?.includes("64d326cd869f4f8664538775") ? (
                  <>
                    <div style={{ margin: "10px auto" }}>
                      <Button size="lg" onClick={handleOfferNonIchilovDoctor}>
                        {" "}
                        {t("Offer Hidoc doctor")}
                      </Button>
                    </div>
                    <div style={{ margin: "10px auto" }}>
                      <Button size="lg" onClick={handleConvertHidocMeeting}>
                        {" "}
                        {t("Convert Hidoc Meeting")}
                      </Button>
                    </div>
                  </>
                ) : null}

                {updateData?.statuses === "Scheduled" &&
                  !updateData?.consultation?.meetingGroupId &&
                  !updateData?.dyteMeetingResponse?.additionalParticipant && (
                    <div style={{ margin: "10px auto" }}>
                      <Button size="lg" onClick={handleAddParticipant}>
                        {t("Add dyte participant")}
                      </Button>
                    </div>
                  )}
              </Form>
            )}

            <div style={{ margin: "0 auto", paddingBottom: "20px" }}>
              <Button size="lg" onClick={UpdateDetails}>
                {" "}
                {t("Update")}
              </Button>
            </div>
          </MDBCardBody>
        </MDBCard>
      </Modal>
      <Modal
        open={priceModal}
        onClose={() => setPriceModal(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="modalDiv"
        style={{
          backgroundColor: "#FFFFFF",
          width: "30%",
          margin: "5% auto",
          height: "30%",
        }}
      >
        <MDBCard className="documentBody">
          <MDBCardBody>
            <MDBCardText> {t("Update Meeting Price")}</MDBCardText>
            <div className="priceInput">
              <input
                type="text"
                className="form-control"
                defaultValue={updateData ? updateData?.meetingPrice : ""}
                onChange={(e) => setUpdatePrice(e.target.value)}
              />
            </div>

            <div className="yes_no">
              <Button
                size="sm"
                className="document1"
                onClick={() => setPriceModal(false)}
              >
                {" "}
                {t("Close")}
              </Button>
              <Button
                size="sm"
                className="document1"
                onClick={handlePriceChange}
              >
                {" "}
                {t("Update")}
              </Button>
            </div>
          </MDBCardBody>
        </MDBCard>
      </Modal>
      {participantModal && (
        <AddParticipantModal
          show={participantModal}
          handleClose={handleCloseAddParticipant}
          meetingId={updateId}
          refetchAllmeetings={refetchAllmeetings}
          updatedData={updateData}
          allMeetingData={allMeetingData}
          setUpdateData={setUpdateData}
        />
      )}
    </div>
  );
};

export default MeetingUpdate;
