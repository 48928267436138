import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Spinner } from "react-bootstrap";
import { toast } from "react-toastify";
import "../Dashborad/client.css";
import ViewDocument from "./ViewDocument";
import moment from "moment";
import ViewSurvey from "./ViewSurvey";
import CommentModal from "./CommentModal";
import { useTranslation } from "react-i18next";
import { Network } from "../../config/axios";
import SummaryComponent from "./SummaryComponent";
import DocWithSummaryModel from "./DocWithSummaryModel";
import { CheckIcon } from "../common/icon";
export function ageCalculator(d) {
  var dob = new Date(d);

  var month_diff = Date.now() - dob.getTime();
  var age_dt = new Date(month_diff);
  var year = age_dt.getUTCFullYear();
  var age = Math.abs(year - 1970);
  return age;
}

const DoctorFuture = (props) => {
  const { t } = useTranslation();
  const token = localStorage.getItem("token");
  const [documentList, setDocumenetList] = useState();
  const [pacseeDocument, setPacseeDocument] = useState([]);
  const [allPdfDocument, setAllPdfDocument] = useState([]);
  const [documentModal, setDocumentModal] = useState(false);
  const handleClose = () => (setDocumentModal(false), setPacseeDocument([]));
  const [mid, setMId] = useState();
  const [cdata, setCdata] = useState();
  const [summaryId, setSummaryId] = useState();
  const [updateSummaryResponce, setUpdateSummaryResponse] = useState({});
  const [prescriptionResponce, setUpdateProscriptionResponse] = useState([]);
  const [remaintime, setRemainTime] = useState();
  const [docSummary, setDocSummary] = useState(false);
  const [meetingData, setMeetingData] = useState("");
  const [userName, setUserName] = useState("");
  const [checkIDF, setCheckIDF] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [filterData, setFilterData] = useState(props?.allAppointment);
  const [loading, setLoading] = useState({ id: "" });
  const [currentUserData, setCurrentUserData] = useState();
  const [doctorData, setDoctorData] = useState();
  const [hasMeetingType, setHasMeetingType] = useState(false);


  const weekday = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
  useEffect(() => {
    setInterval(() => {
      setRemainTime(new Date());
    }, 1000);
  }, []);
  // const openZoom = (data) => {
  //   window.open(data);
  // };

  const mridocument = async (id) => {
    try {
      const response = await Network().get(
        `/users/pacseeMeetingDocuments?meetingId=${id}`,
        {
          headers: {
            authorization: token,
          },
        }
      );

      setPacseeDocument(response.data.pacseeFiles);
    } catch (error) {
      console.log(error);
    }
  };
  const handleSendMessage = async (id) => {
    try {
      setLoading({ id: id });
      const data = {
        meetingId: id,
      };
      const response = await Network().post(
        `doctor/sendNotificationOnNoShow`,
        data,
        {
          headers: {
            authorization: token,
          },
        }
      );
      const {
        data: { statusCode, message },
      } = response || {};
      if ([200, 201, 202].includes(statusCode) || response.status == 201) {
        toast.success(t(message));
        if (token) {
          props.handleClose3();
        }
      } else {
        toast.error(t(message));
      }
      setLoading({ id: "" });
    } catch (error) {
      console.log(error);
      setLoading({ id: "" });
    }
  };
  const summaryModal = (e, id, prescription, summary, data) => {
    e.preventDefault();
    setCurrentUserData(data?.userDetails);
    setDoctorData(data?.doctorDetails);
    setUserName(
      `${data?.userDetails?.first_name}  ${data?.userDetails?.last_name}`
    );
    setCheckIDF(data?.consultation[0]?.meetingGroupId);
    setSummaryId(id);
    setUpdateSummaryResponse(summary);
    setUpdateProscriptionResponse(prescription);
    setHasMeetingType(Object.prototype.hasOwnProperty.call(data, 'meetingType'));
    props.setOpen(true);
  };
  const handleDocWithSummaryModel = (
    e,
    id,
    prescription,
    summary,
    docList,
    mriDoc,
    data
  ) => {
    e.preventDefault();
    setSummaryId(id);
    setUpdateSummaryResponse(summary);
    setUpdateProscriptionResponse(prescription);
    setMeetingData(data);
    setDocSummary(true);
    setUserName(
      `${data?.userDetails?.first_name}  ${data?.userDetails?.last_name}`
    );
    setCheckIDF(data?.consultation[0]?.meetingGroupId);
    setCurrentUserData(data?.userDetails);
  };
  const handleCloseSumm = () => {
    props.handleCloseSummary();
    setUpdateSummaryResponse({});
  };
  const handleCloseDocSummaryModel = () => {
    setDocSummary(false);
    props.handleCloseSummary();
    setUpdateSummaryResponse({});
    setSummaryId();
  };
  const handleFilterDate = (e) => {
    setStartDate(e[0]);
    setEndDate(e[1]);
    handleFilterDateData(e);
  };
  const handleFilterDateData = (e) => {
    let datefliterData = props?.allAppointment.filter((x) => {
      const appntDt = new Date(x?.scheduledDate);
      appntDt.setHours(0);
      appntDt.setMinutes(0);
      appntDt.setSeconds(0);
      appntDt.setMilliseconds(0);
      const startTime = new Date(e[0]).getTime();
      const appntTime = appntDt.getTime();
      const endTime = new Date(e[1]).getTime();
      return appntTime >= startTime && appntTime <= endTime;
    });
    setFilterData(datefliterData);
    if (
      (e[0] === null && e[1] === null) ||
      (e[0] === "" && e[1] === "") ||
      (e[0] === undefined && e[1] === undefined)
    ) {
      setFilterData(props.allAppointment);
    }
  };

  useEffect(() => {
    setFilterData(props.allAppointment);
  }, [props.allAppointment]);

  useEffect(() => {
    if (startDate && endDate) {
      handleFilterDateData([startDate, endDate]);
    }
  }, [props?.allAppointment]);

  const openDyteMeeting = (event, meeting) => {
   
    if (meeting?.videoPlatform === "dyte") {
      window.open(
        `/dyte-video-call/${meeting._id}`,
        "_blank",
        "location=yes,height=800,width=640,scrollbars=yes,status=yes"
      );
    } else {
      window.open(meeting?.meetingLinkDoctor, "_blank");
    }

    //window.open(`/dyte-video-call/${meeting._id}`, '_blank');
  };

  return (
    <div>
      <SummaryComponent
        open={props.open}
        updateSummaryResponce={updateSummaryResponce}
        prescriptionResponce={prescriptionResponce}
        setUpdateSummaryResponse={setUpdateSummaryResponse}
        setOpen={props.setOpen}
        meetingId={summaryId}
        handleClose={handleCloseSumm}
        userName={userName}
        checkIDF={checkIDF}
        userData={currentUserData}
        doctorData={doctorData}
        hasMeetingType={hasMeetingType}
      />
      {docSummary ? (
        <DocWithSummaryModel
          handleClose={handleCloseDocSummaryModel}
          setOpen={setDocSummary}
          open={docSummary}
          documentList={documentList}
          setDocumentModal={setDocumentModal}
          pacseeDocument={pacseeDocument}
          meetingId={summaryId}
          updateSummaryResponce={updateSummaryResponce}
          prescriptionResponce={prescriptionResponce}
          setUpdateSummaryResponse={setUpdateSummaryResponse}
          meetingData={meetingData}
          userName={userName}
          checkIDF={checkIDF}
          allPdfDocument={allPdfDocument}
          remaintime={remaintime}
          userData={currentUserData}
        />
      ) : null}
      <ViewDocument
        documentList={documentList}
        documentModal={documentModal}
        setDocumentModal={setDocumentModal}
        handleClose={handleClose}
        pacseeDocument={pacseeDocument}
        allPdfDocument={allPdfDocument}
      />
      <ViewSurvey
        medicalq={props.medicalq}
        handleClose={props.handleClose5}
        qData={props.qData}
      />

      <CommentModal
        handleClose={props.handleClose3}
        open={props.comentModal}
        mid={mid}
        cdata={cdata}
        note={props.note}
        setNote={props.setNote}
      />
      <section className="userWrapper doctor-user">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="custom-date-picker">
                <DatePicker
                  dateFormat="dd/MM/yyyy"
                  selected={startDate}
                  startDate={startDate}
                  endDate={endDate}
                  onChange={(e) => handleFilterDate(e)}
                  dayPlaceholder="DD"
                  monthPlaceholder="MM"
                  yearPlaceholder="YYYY"
                  selectsRange
                  isClearable
                  showIcon
                  className="custom-date-range"
                  placeholderText="Click to select  dates"
                  showPopperArrow={false}
                  locale="he"
                />
              </div>
            </div>
            <div className="col-lg-12">
              <div className="userInnerWrapper userInnerWrapB">
                {props.loader &&
                (props.allAppointment.length === 0 || props.tabClick) ? (
                  <h2>{t("Please wait")} ...</h2>
                ) : (
                  <ul>
                    {filterData.length !== 0 ? (
                      filterData &&
                      filterData
                        .sort(
                          (objA, objB) =>
                            new Date(objA.scheduledDate) -
                            new Date(objB.scheduledDate)
                        )
                        .map((data) => {
                          return (
                            <li key={data?._id} id={data?._id}>
                              <div className="cfBase">
                                <div className="cfFirst">
                                  <div className="cfImgbg">
                                    <div className="cfImgTxt">
                                      <h3>{t("Patient")}</h3>
                                      <h2>
                                        {data?.patientDetails
                                          ? data?.patientDetails.first_name
                                          : ""}{" "}
                                        {data?.patientDetails
                                          ? data?.patientDetails.last_name
                                          : ""}
                                      </h2>
                                      <h4>
                                        {t("Age")}-
                                        {data?.patientDetails?.patientDOB
                                          ? ageCalculator(
                                              data.patientDetails.patientDOB
                                            )
                                          : t("N/A")}{" "}
                                        {t("years")}
                                      </h4>
                                      <h4>
                                        {t("Gender")}-
                                        {t(data?.patientDetails?.patientGender)}
                                      </h4>
                                      <h5>
                                        {t("Reason for referral")}-{" "}
                                        {t(data?.requestDescription)}
                                      </h5>
                                    </div>
                                    {data?.consultation[0]?.meetingGroupId &&
                                    data?.consultation[0]?.meetingGroupId ===
                                      "640ed7103f08dcee2d1f1719" ? (
                                      <div className="cfInfo patient-unavail">
                                        {new Date(
                                          data?.scheduledDate
                                        ).setMinutes(
                                          new Date(
                                            data?.scheduledDate
                                          ).getMinutes() - 10
                                        ) <= remaintime ? (
                                          <button
                                            className="active"
                                            onClick={() =>
                                              handleSendMessage(data?._id)
                                            }
                                            disabled={loading.id === data?._id}
                                          >
                                            {loading.id === data?._id ? (
                                              <Spinner
                                                animation="border"
                                                size="sm"
                                              />
                                            ) : (
                                              t("The patient is unavailable")
                                            )}
                                          </button>
                                        ) : (
                                          <button>
                                            {loading.id === data?._id ? (
                                              <Spinner
                                                animation="border"
                                                size="sm"
                                              />
                                            ) : (
                                              t("The patient is unavailable")
                                            )}
                                          </button>
                                        )}
                                      </div>
                                    ) : null}
                                  </div>
                                  <div className="cfDate">
                                    <h3>
                                      {moment(data?.scheduledDate).format(
                                        "DD.MM.YYYY"
                                      )}
                                    </h3>
                                    <span>
                                      {moment(data?.scheduledDate).format(
                                        "HH:mm"
                                      )}
                                    </span>
                                    <span>
                                      {" "}
                                      {t(
                                        weekday[
                                          new Date(data?.scheduledDate).getDay()
                                        ]
                                      )}
                                    </span>
                                    {data?.isFollowUp ? (
                                      <h4 className="followup-text">
                                        {t("Follow Up Meeting")}
                                      </h4>
                                    ) : null}
                                  </div>
                                </div>
                                <div
                                  className={`cflast ${
                                    data?.userDetails?.phone
                                      ? "unavailable-btn"
                                      : ""
                                  }`}
                                >
                                  {
                                    <div className="cfInfo">
                                      <div className="cfChk">
                                        {new Date(
                                          data?.scheduledDate
                                        ).setMinutes(
                                          new Date(
                                            data?.scheduledDate
                                          ).getMinutes() - 10
                                        ) <= remaintime ? (
                                          <>
                                            <CheckIcon />
                                            <h3 className="active">
                                              {" "}
                                              {t("Start meeting")}
                                            </h3>
                                          </>
                                        ) : (
                                          <h3 className="active no_document">
                                            {" "}
                                            {t("Start meeting")}
                                          </h3>
                                        )}
                                      </div>
                                      {new Date(data?.scheduledDate).setMinutes(
                                        new Date(
                                          data?.scheduledDate
                                        ).getMinutes() - 10
                                      ) <= remaintime ? (
                                        <button
                                          className="active"
                                          onClick={(e) =>
                                            openDyteMeeting(e, data)
                                          }
                                        
                                        >
                                          {t("Open")}
                                        </button>
                                      ) : (
                                        <button>{t("Open")}</button>
                                      )}
                                    </div>
                                  }

                                  {data?.userDetails?.phone ? (
                                    <div className="cfInfo">
                                      <div className="cfChk">
                                        <CheckIcon />
                                        <h3 className="active phone-num">
                                          {data?.userDetails?.phone}
                                        </h3>
                                      </div>
                                      <a
                                        className="active"
                                        href={`zoomphonecall://${data?.userDetails?.phone}`}
                                      >
                                        {t("phone")}
                                      </a>
                                    </div>
                                  ) : null}

                                  <div className="cfInfo doc-w-summary">
                                    <div className="cfChk">
                                      {data.summary ||
                                      data.documents.length != 0 ||
                                      data?.idfMeetingSummaries?.length !== 0 ||
                                      data?.userDetails?.pacseeAccount ? (
                                        <>
                                          <CheckIcon />
                                          <h3 className="active">
                                            {" "}
                                            {t("Docs with summary")}
                                          </h3>
                                        </>
                                      ) : (
                                        <h3 className="no_document">
                                          {" "}
                                          {t("Docs with summary")}
                                        </h3>
                                      )}
                                    </div>

                                    {data.summary ||
                                    data.documents.length != 0 ||
                                    data?.idfMeetingSummaries?.length !== 0 ||
                                    data?.userDetails?.pacseeAccount ? (
                                      <button
                                        className="active"
                                        onClick={(e) =>
                                          handleDocWithSummaryModel(
                                            e,
                                            data._id,
                                            data?.prescription,
                                            data?.summary,
                                            setDocumenetList(data.documents),
                                            mridocument(data._id),
                                            data,
                                            setAllPdfDocument([
                                              ...data.documents,
                                              ...data.idfMeetingSummaries,
                                            ])
                                          )
                                        }
                                      >
                                        {t("update")}
                                      </button>
                                    ) : (
                                      <button
                                        onClick={(e) =>
                                          handleDocWithSummaryModel(
                                            e,
                                            data._id,
                                            data?.prescription,
                                            data?.summary,
                                            setDocumenetList(data.documents),
                                            mridocument(data._id),
                                            data,
                                            setAllPdfDocument([
                                              ...data.documents,
                                              ...data.idfMeetingSummaries,
                                            ])
                                          )
                                        }
                                      >
                                        {t("update")}
                                      </button>
                                    )}
                                  </div>
                                  <div className="cfInfo">
                                    <div className="cfChk">
                                      {data.summary ? (
                                        <>
                                          <CheckIcon />
                                          <h3 className="active">
                                            {" "}
                                            {t("Summary/Prescription")}
                                          </h3>
                                        </>
                                      ) : (
                                        <h3 className="active no_document">
                                          {" "}
                                          {t("Summary/Prescription")}
                                        </h3>
                                      )}
                                    </div>
                                    {data.summary ? (
                                      <button
                                        className="active"
                                        onClick={(e) =>
                                          summaryModal(
                                            e,
                                            data._id,
                                            data?.prescription,
                                            data?.summary,
                                            data
                                          )
                                        }
                                      >
                                        {t("update")}
                                      </button>
                                    ) : (
                                      <button
                                        onClick={(e) =>
                                          summaryModal(
                                            e,
                                            data._id,
                                            data?.prescription,
                                            data?.summary,
                                            data
                                          )
                                        }
                                      >
                                        {t("not ready")}
                                      </button>
                                    )}
                                  </div>

                                  <div className="cfInfo">
                                    <div className="cfChk">
                                      {data.documents.length !== 0 ||
                                      data?.prescription?.length !== 0 ||
                                      data?.idfMeetingSummaries?.length !== 0 ||
                                      data?.userDetails?.pacseeAccount ? (
                                        <>
                                          <CheckIcon />
                                          <h3 className="active">
                                            {" "}
                                            {t("View Document")}
                                          </h3>
                                        </>
                                      ) : (
                                        <h3 className="active no_document">
                                          {" "}
                                          {t("View Document")}
                                        </h3>
                                      )}
                                    </div>
                                    {data.documents.length !== 0 ||
                                    data?.prescription?.length !== 0 ||
                                    data?.idfMeetingSummaries?.length !== 0 ||
                                    data?.userDetails?.pacseeAccount ? (
                                      <button
                                        className="active"
                                        onClick={() => (
                                          setAllPdfDocument([
                                            ...data.documents,
                                            ...data.idfMeetingSummaries,
                                          ]),
                                          setDocumenetList(data.documents),
                                          setDocumentModal(true),
                                          mridocument(data._id)
                                        )}
                                      >
                                        {t("download")}
                                      </button>
                                    ) : (
                                      <button>{t("not ready")}</button>
                                    )}
                                  </div>
                                  <div className="cfInfo">
                                    <div className="cfChk">
                                      {data.survey?.mainComplaint ||
                                      data.survey?.medicalHistory ||
                                      data.survey?.medications ? (
                                        <>
                                          <CheckIcon />
                                          <h3 className="active ">
                                            {t("Medical questionnaire")}
                                          </h3>
                                        </>
                                      ) : (
                                        <h3 className="active no_document">
                                          {t("Medical questionnaire")}
                                        </h3>
                                      )}
                                    </div>
                                    {data.survey?.mainComplaint ||
                                    data.survey?.medicalHistory ||
                                    data.survey?.medications ? (
                                      <button
                                        className="active"
                                        onClick={() => (
                                          props.setMedicalQ(true),
                                          props.setQData(data.survey)
                                        )}
                                      >
                                        {t("View")}
                                      </button>
                                    ) : (
                                      <button>{t("not ready")}</button>
                                    )}
                                  </div>
                                  <div className="cfInfo">
                                    {data?.comments ? (
                                      <div className="cfChk">
                                        <CheckIcon />
                                        <h3 className="active ">
                                          {t("Meeting comments")}
                                        </h3>
                                      </div>
                                    ) : (
                                      <div className="cfChk">
                                        <h3 className="active no_document">
                                          {t("Meeting comments")}
                                        </h3>
                                      </div>
                                    )}
                                    {data?.comments ? (
                                      <button
                                        className="active"
                                        onClick={() => (
                                          props.setCommentModal(true),
                                          setMId(data._id),
                                          setCdata(data.comments.doctorComment),
                                          props.setNote(
                                            data?.comments?.doctorComment
                                          )
                                        )}
                                      >
                                        {t("update")}
                                      </button>
                                    ) : (
                                      <button
                                        onClick={() => (
                                          props.setCommentModal(true),
                                          setMId(data._id)
                                        )}
                                      >
                                        {t("add")}
                                      </button>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </li>
                          );
                        })
                    ) : (
                      <h3 className="no_meeting">
                        {t("You have no future meeting")}
                      </h3>
                    )}
                  </ul>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};
export default DoctorFuture;
