import React, { useState, useEffect, useCallback } from "react";

import { Button, Form, InputGroup } from "react-bootstrap";
import "../contact/contact.css";
import { Modal } from "@material-ui/core";
import ImageCrop from "../../My Queues/ImageCrop";
import { toast } from "react-toastify";
import Multiselect from "multiselect-react-dropdown";
import { useTranslation } from "react-i18next";
import { SelectDatepicker } from "react-select-datepicker";
import $ from "jquery";
import { RotatingLines } from "react-loader-spinner";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import {
  userGetUserProfile,
  allConsultation,
  doctorNoticiation,
} from "../../config/service";
import { handleKeyPress } from "../Login/login";
import profile from "../../assets/images/dashboard/docProfile.png";
import { Network } from "../../config/axios";
import { checkEmailFormate, validateIsraeliID } from "../../utils/function";
import DownloadRxSummary from "./DownloadRxSummary";
import moment from "moment";

const Profile = () => {
  const { t } = useTranslation();
  let lang = localStorage.getItem("lang");
  const role = localStorage.getItem("role");
  const token = localStorage.getItem("token");
  const [show, setShow] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [imageData1, setImageData1] = useState();
  const [profileDetails, setProfileDetails] = useState();
  const [terms, setTerms] = useState(false);
  const [consent, setConsent] = useState(false);
  const [calenderInvite, setCalenderInvite] = useState(true);
  const [calendarEmail, setCalendarEmail] = useState();
  const [userDOB, setUserDOB] = useState();
  const patienttype = [
    { eng: "Children", heb: "ילד.ה" },
    { eng: "Adults", heb: "מבוגר" },
  ];
  const [formValue, setformValue] = React.useState({
    firstname: "",
    lastname: "",
    email: "",
    mobile_number: "",
    id: "",
    dob: "",
    clientGender: "",
    MaritalStatus: "",
    permanent: "",
    street: "",
    postalcode: "",
    localphoneNumber: "",
    city: "",
    houseNo: "",
    country: "",
    doctor_license: "",
    title: "",
    speciality: [],
    experience: "",
    bio: "",
  });
  const [passwordValue, setPasswordValue] = React.useState({
    oldPassword: "",
    password: "",
  });
  const [consultData, setConsultData] = useState([]);
  const [selectedValue, setSelectedValue] = useState([]);
  const [selectedtype, setSelectedType] = useState([]);
  const [selectedSubscriptions, setSelectedSubscriptions] = useState([]);
  const [id, setSelectId] = useState([]);
  const [typevalue, setTypeValue] = useState([]);
  const [profileImage, setProfileImage] = useState("");
  const [doctorData, setDoctorData] = useState("");
  const handleClose = () => setModalShow(false);
  const [showOld, setShowOld] = useState(false);
  const [showNew, setShowNew] = useState(false);
  useEffect(() => {
    if (role !== "admin") {
      consultingFields();
      userDetails();
      setShow(profileDetails?.isFromIsrael);
      setTerms(profileDetails?.termsAndConditions);
    }
  }, []);
  const onDateChange = useCallback((date) => {
    setUserDOB(date);
  }, []);

  const userDetails = async () => {
    const data = {
      userId: localStorage.getItem("userId"),
    };
    const response = await userGetUserProfile(data);
    if (localStorage.getItem("role") === "client") {
      setProfileDetails(response.data.profileDetails);
      setTerms(response?.data?.profileDetails?.termsAndConditions);
      setShow(response?.data?.profileDetails?.isFromIsrael);
      setConsent(response?.data?.profileDetails?.insuranceConsent);
      setProfileImage(response.data.profileDetails?.croppedAvatar);
      setUserDOB(
        response.data.profileDetails.clientDOB
          ? new Date(response.data.profileDetails.clientDOB)
          : null
      );
    } else {
      setProfileDetails(response.data.profileDetails[0]);
      setTerms(response?.data?.profileDetails[0].termsAndConditions);
      setShow(response?.data?.profileDetails[0].isFromIsrael);
      setProfileImage(
        response.data.profileDetails[0].croppedAvatar
          ? response.data.profileDetails[0].croppedAvatar
          : profile
      );
      setUserDOB(
        response.data.profileDetails[0].clientDOB
          ? new Date(response.data.profileDetails[0]?.clientDOB)
          : null
      );
      setDoctorData(response.data.profileDetails[0].doctorData[0]);
      setSelectedValue(
        response.data.profileDetails[0].doctorData[0].consultationSpeciality
      );
      setCalenderInvite(
        response.data.profileDetails[0].doctorData[0]?.sendCalendarInvite
      );
      setSelectedType(
        response.data?.profileDetails[0]?.doctorData[0]?.patientsCategory.map(
          (d) => {
            return { eng: d, heb: d == "Children" ? "ילד.ה" : "מבוגר" };
          }
        )
      );
      setSelectedSubscriptions(
        response?.data?.profileDetails[0]?.doctorData[0]?.subscriptions.map(
          (data) => {
            return data;
          }
        )
      );
      setSelectId(
        response.data?.profileDetails[0]?.doctorData[0]?.consultationSpeciality
      );
      setTypeValue(
        response.data?.profileDetails[0]?.doctorData[0]?.patientsCategory
      );
    }
  };
  const onSelect = (selectedList, selectedItem) => {
    setSelectedValue([...selectedValue, selectedItem]);
    setSelectId([...id, selectedItem._id]);
  };

  const onRemove = (selectedList, removedItem) => {
    setSelectedValue(
      selectedValue.filter((d) => {
        return d != removedItem;
      })
    );
    setSelectId(
      id.filter((d) => {
        return d != removedItem._id;
      })
    );
  };

  const onSelectType = (selectedList, selectedItem) => {
    setSelectedType([...selectedtype, selectedItem]);
    setTypeValue([...typevalue, selectedItem.eng]);
  };

  const onRemoveType = (selectedList, removedItem) => {
    setSelectedType(
      selectedtype.filter((d) => {
        return d != removedItem;
      })
    );
    setTypeValue(
      typevalue.filter((d) => {
        return d != removedItem.eng;
      })
    );
  };

  const consultingFields = async () => {
    const response = await allConsultation();
    setConsultData(response.data.result);
  };

  const AddressHandleSubmit = async (e) => {
    e.preventDefault();
    // store the states in the form data
    const token = localStorage.getItem("token");

    let data =
      localStorage.getItem("role") === "client"
        ? {
            first_name: formValue.firstname
              ? formValue.firstname
              : profileDetails.first_name,
            last_name: formValue.lastname
              ? formValue.lastname
              : profileDetails.last_name,
            email: formValue.email ? formValue.email : profileDetails.email,
            phone: formValue.mobile_number
              ? formValue.mobile_number
              : profileDetails.phone,
            id: formValue.id ? formValue.id : profileDetails.id,
            clientDOB: moment(userDOB).hour(12).minute(0).format("YYYY-MM-DD hh:mm:ss"),
            clientGender: formValue.clientGender
              ? formValue.clientGender
              : profileDetails.clientGender,
            clientMaritalStatus: formValue.MaritalStatus
              ? formValue.MaritalStatus
              : profileDetails.clientMaritalStatus,
            permanentOrRetired: formValue.permanent
              ? formValue.permanent
              : profileDetails.permanentOrRetired,
            isFromIsrael: show,
            termsAndConditions: terms,
            insuranceConsent: consent,
            clientAddress: {
              street: formValue.street
                ? formValue.street
                : profileDetails?.clientAddress?.street,
              pincode: formValue.postalcode
                ? formValue.postalcode
                : profileDetails?.clientAddress?.pincode,
              localPhone: formValue.localphoneNumber
                ? formValue.localphoneNumber
                : profileDetails?.clientAddress?.localPhone,
              city: formValue.city
                ? formValue.city
                : profileDetails?.clientAddress?.city,
              house: formValue.houseNo
                ? formValue.houseNo
                : profileDetails?.clientAddress?.house,
              country: formValue.country ? formValue.country : "Israel",
            },
          }
        : {
            first_name: formValue.firstname
              ? formValue.firstname
              : profileDetails?.first_name,
            last_name: formValue.lastname
              ? formValue.lastname
              : profileDetails?.last_name,
            email: formValue.email ? formValue.email : profileDetails.email,
            phone: formValue.mobile_number
              ? formValue.mobile_number
              : profileDetails?.phone,
            id: formValue.id ? formValue.id : profileDetails?.id,
            clientDOB: moment(userDOB).hour(12).minute(0).format("YYYY-MM-DD hh:mm:ss"),
            clientGender: formValue.clientGender
              ? formValue.clientGender
              : profileDetails?.clientGender,
            clientMaritalStatus: formValue.MaritalStatus
              ? formValue.MaritalStatus
              : profileDetails?.clientMaritalStatus,
            permanentOrRetired: formValue.permanent
              ? formValue.permanent
              : profileDetails?.permanentOrRetired,
            licenceNumber: formValue.doctor_license
              ? formValue.doctor_license
              : profileDetails?.doctorData[0].licenceNumber,
            title: formValue.title
              ? formValue.title
              : profileDetails?.doctorData[0].title,
            consultationSpeciality:
              id.length != 0
                ? id
                : profileDetails?.doctorData[0].consultationSpeciality,
            experience: formValue.experience
              ? formValue.experience
              : profileDetails?.doctorData[0].experience,
            bio: formValue.bio
              ? formValue.bio
              : profileDetails?.doctorData[0].bio,
            patientsCategory: typevalue,

            isFromIsrael: show,
            termsAndConditions: terms,
            sendCalendarInvite: calenderInvite,
            calendarEmail: calendarEmail || "",
            clientAddress: {
              street: formValue.street
                ? formValue.street
                : profileDetails?.clientAddress?.street,
              pincode: formValue.postalcode
                ? formValue.postalcode
                : profileDetails?.clientAddress?.pincode,
              localPhone: formValue.localphoneNumber
                ? formValue.localphoneNumber
                : profileDetails?.clientAddress?.localPhone,
              city: formValue.city
                ? formValue.city
                : profileDetails?.clientAddress?.city,
              house: formValue.houseNo
                ? formValue.houseNo
                : profileDetails?.clientAddress?.house,
              country: formValue.country
                ? formValue.country
                : profileDetails?.clientAddress?.country,
            },
          };
    const isEmailCorrect = checkEmailFormate(data?.email);
    if (!isEmailCorrect) {
      toast.error("Please enter valid email");
      return;
    }
    const isValidId = validateIsraeliID(data.id);

    if (!isValidId) {
      toast.error(t("The ID number you entered is invalid"));
      return;
    }
    if (data?.calendarEmail) {
      const isCalendarEmailCorrect = checkEmailFormate(data?.calendarEmail);
      if (!isCalendarEmailCorrect) {
        toast.error("Please enter valid email for calendar invitations");
        return;
      }
    }

    // make axios post request
    try {
      const response = await Network().patch("/users/updateUserProfile", data, {
        headers: {
          authorization: token,
          "Content-Type": "application/json",
        },
      });
      if ([200, 201, 202].includes(response.status)) {
        toast.success(t(response.data.msg));
        userDetails();
      }
    } catch (error) {
      toast.error(error.response.data.msg);
      console.log(error);
    }
  };

  const handleNotification = async (flag) => {
    const data = {
      isNotification: flag,
    };
    const response = await doctorNoticiation(data);
    toast.success(t(response.data.message));
    userDetails();
  };

  const handlePasswordToggle = (value) => {
    if (value === "old") {
      setShowOld(!showOld);
    }
    if (value === "new") {
      setShowNew(!showNew);
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    if (name === "id" && role === "client") {
      const valueUpperCase = value.toUpperCase();
      setformValue({
        ...formValue,
        [name]: valueUpperCase,
      });
    } else
      setformValue({
        ...formValue,
        [event.target.name]: event.target.value,
      });
  };

  const handlePasswordChange = (event) => {
    setPasswordValue({
      ...passwordValue,
      [event.target.name]: event.target.value,
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const data = {
      oldPassword: passwordValue.oldPassword,
      password: passwordValue.password,
    };

    const isValid = validate();

    if (isValid) {
      try {
        const response = await Network().patch("/users/changePassword", data, {
          headers: {
            authorization: token,
          },
        });

        if ([200, 201, 202].includes(response.status)) {
          toast.success(t(response.data.msg));
          setPasswordValue({
            oldPassword: "",
            password: "",
          });
        }
      } catch (error) {
        console.log(error);
        const { statusCode, message } = error.response.data;
        if (statusCode === 403) {
          toast.error(t(message));
        }
      }
    }
  };

  let isValidate;

  const validate = () => {
    isValidate = true;

    if (!passwordValue.oldPassword) {
      isValidate = false;
      toast.warning(t("Please Enter Old Password"));
      return;
    }
    if (!passwordValue.password) {
      isValidate = false;
      toast.warning(t("Please Enter New Password"));
      return;
    }
    if (passwordValue.oldPassword === passwordValue.password) {
      isValidate = false;
      toast.warning(
        t("Please make sure that the new password does not match the old one")
      );
      return;
    }
    const passwordRegex =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*]).{8,}$/;
    if (!passwordRegex.test(passwordValue.password)) {
      isValidate = false;
      toast.warning(
        t(
          "Password should contain at least 8 characters, 1 uppercase and 1 lowercase letter, as well as number and symbol (such as !, @, #, $, %, ^, & or *)"
        )
      );
      return;
    }

    return isValidate;
  };

  function handleUpload() {
    // Profile(event.target.files[0]);
    setModalShow(true);
  }

  const ImageThumb = ({ image }) => {
    return <img src={image} />;
  };

  function handleCopyURL(value) {
    navigator.clipboard.writeText(value);
    toast.success("URL copied");
  }

  const handleChangeMode = () => {
    localStorage.removeItem("mode");
    location.reload();
  };

  $("#rsd__select-day option:first-child").text(t("Day"));
  $("#rsd__select-month option:first-child").text(t("Month"));
  $("#rsd__select-year option:first-child").text(t("Year"));

  return (
    <div>
      <Modal
        open={modalShow}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="imagecropmodal"
      >
        <>
          <ImageCrop
            handleClose={handleClose}
            setImageData1={setImageData1}
            userDetails={userDetails}
          />
        </>
      </Modal>

      <div className="content">
        <div className="contact-page profile-page">
          <div className="container ">
            <div className="row ">
              <div className="col-md-12">
                <div className="contact-form ">
                  <div className="contact-form-content">
                    <div>
                      <div>
                        <h2>
                          {" "}
                          {role === "admin"
                            ? t("Change Password")
                            : t("Personal Information")}
                        </h2>
                        {localStorage.getItem("role") === "doctor" && (
                          <div
                            className="team-profile-img"
                            onClick={handleUpload}
                          >
                            {!profileImage ? (
                              <RotatingLines
                                strokeColor="grey"
                                strokeWidth="5"
                                animationDuration="0.75"
                                width="96"
                                visible={true}
                              />
                            ) : imageData1 == null ? (
                              <img
                                src={profileImage ? profileImage : ""}
                                alt=""
                              />
                            ) : (
                              imageData1 && <ImageThumb image={imageData1} />
                            )}
                          </div>
                        )}
                        {role === "admin" ? (
                          <Form
                            onSubmit={handleSubmit}
                            className="change-password"
                          >
                            <Form.Group className="form-group ">
                              <Form.Label>{t("Old Password")}*</Form.Label>
                              <InputGroup>
                                <Form.Control
                                  className="form-control"
                                  name="oldPassword"
                                  type={showOld ? "text" : "password"}
                                  value={passwordValue.oldPassword}
                                  onChange={handlePasswordChange}
                                  id="oldPassword"
                                  placeholder={t("Please Enter Old Password")}
                                />
                                <span
                                  onClick={() => handlePasswordToggle("old")}
                                >
                                  {showOld ? (
                                    <VisibilityOffOutlinedIcon />
                                  ) : (
                                    <RemoveRedEyeOutlinedIcon />
                                  )}
                                </span>
                              </InputGroup>
                            </Form.Group>
                            <Form.Group className="form-group">
                              <Form.Label>{t("New Password")}* </Form.Label>
                              <InputGroup>
                                <Form.Control
                                  className="form-control"
                                  name="password"
                                  type={showNew ? "text" : "password"}
                                  value={passwordValue.password}
                                  onChange={handlePasswordChange}
                                  id="password"
                                  placeholder={t("Please Enter New Password")}
                                />
                                <span
                                  onClick={() => handlePasswordToggle("new")}
                                >
                                  {showNew ? (
                                    <VisibilityOffOutlinedIcon />
                                  ) : (
                                    <RemoveRedEyeOutlinedIcon />
                                  )}
                                </span>
                              </InputGroup>
                              <Form.Text id="passwordHelpBlock" muted>
                                {t(
                                  "Password should contain at least 8 characters, 1 uppercase and 1 lowercase letter, as well as number and symbol (such as !, @, #, $, %, ^, & or *)"
                                )}
                              </Form.Text>
                            </Form.Group>
                            <Button type="submit">Submit</Button>
                          </Form>
                        ) : (
                          <>
                            <div className="col-md-8"></div>
                            <Form>
                              <Form.Group className="form-group ">
                                <Form.Label>{t("First Name")}</Form.Label>
                                <input
                                  className="form-control"
                                  name="firstname"
                                  type="text"
                                  onChange={handleChange}
                                  id="firstname"
                                  defaultValue={
                                    profileDetails
                                      ? profileDetails.first_name
                                      : ""
                                  }
                                />
                              </Form.Group>
                              <Form.Group className="form-group">
                                <Form.Label>{t("Last Name")} </Form.Label>
                                <input
                                  className="form-control"
                                  name="lastname"
                                  type="text"
                                  onChange={handleChange}
                                  id="lastname"
                                  defaultValue={
                                    profileDetails
                                      ? profileDetails.last_name
                                      : ""
                                  }
                                />
                              </Form.Group>
                              <Form.Group className="form-group">
                                <Form.Label>{t("Email")} </Form.Label>
                                <input
                                  className="form-control forceLtrInput"
                                  name="email"
                                  type="text"
                                  onChange={handleChange}
                                  id="email"
                                  defaultValue={
                                    profileDetails ? profileDetails.email : ""
                                  }
                                />
                              </Form.Group>

                              <Form.Group className="form-group">
                                <Form.Label>{t("Mobile Number")}</Form.Label>
                                <input
                                  className="form-control forceLtrInput phone_number"
                                  name="mobile_number"
                                  type="text"
                                  // value={formValue.phoneNumber}
                                  onChange={handleChange}
                                  id="mobile_number"
                                  defaultValue={
                                    profileDetails ? profileDetails.phone : ""
                                  }
                                />
                              </Form.Group>
                              {localStorage.getItem("role") === "doctor" ? (
                                <Form.Group className="form-group">
                                  <Form.Label>Direct link</Form.Label>
                                  <div className="direct-link-grid">
                                    <input
                                      className="form-control forceLtrInput direct-link"
                                      name="direct-link"
                                      type="text"
                                      disabled
                                      id="direct-link"
                                      value={`https://hidoc.co.il/?docId=${doctorData?.doctorId}`}
                                    />
                                    <Button
                                      onClick={() => {
                                        handleCopyURL(
                                          `https://hidoc.co.il/?docId=${doctorData?.doctorId}`
                                        );
                                      }}
                                    >
                                      Copy <i class="las la-copy"></i>
                                    </Button>
                                  </div>
                                </Form.Group>
                              ) : null}
                              <Form.Group className="form-group">
                                <Form.Label>{t("ID Number")} </Form.Label>
                                <input
                                  className="form-control textcenter"
                                  name="id"
                                  type={role === "client" ? "text" : "number"}
                                  onWheel={(e) => e.target.blur()}
                                  // value={formValue.phoneNumber}
                                  onChange={handleChange}
                                  id="id"
                                  onKeyPress={handleKeyPress}
                                  onInput={(e) =>
                                    (e.target.value = e.target.value.slice(
                                      0,
                                      14
                                    ))
                                  }
                                  defaultValue={
                                    profileDetails ? profileDetails.id : ""
                                  }
                                />
                              </Form.Group>
                              <Form.Group className="form-group form-date-picker">
                                <Form.Label>{t("Date of Birth")}</Form.Label>
                                <SelectDatepicker
                                  order="day/month/year"
                                  selectedDate={userDOB}
                                  onDateChange={onDateChange}
                                />
                              </Form.Group>
                              <Form.Group className="form-group">
                                <Form.Label>{t("Gender")} </Form.Label>
                                {profileDetails && (
                                  <select
                                    className="form-control text"
                                    name="clientGender"
                                    onChange={handleChange}
                                    defaultValue={profileDetails?.clientGender}
                                  >
                                    <option>{t("Select")}</option>
                                    <option value="Male">{t("Male")}</option>
                                    <option value="Female">
                                      {t("Female")}
                                    </option>
                                  </select>
                                )}
                              </Form.Group>
                              {localStorage.getItem("role") ===
                              "doctor" ? null : (
                                <>
                                  <Form.Group className="form-group">
                                    <Form.Label>
                                      {t("Marital Status")}
                                    </Form.Label>
                                    {profileDetails && (
                                      <select
                                        className="form-control text"
                                        name="MaritalStatus"
                                        defaultValue={
                                          profileDetails?.clientMaritalStatus
                                        }
                                        onChange={handleChange}
                                      >
                                        <option>{t("Select")}</option>
                                        <option value="Married">
                                          {t("Married")}
                                        </option>
                                        <option value="Single">
                                          {t("Single")}
                                        </option>
                                        <option value="Divorced">
                                          {t("Divorced")}
                                        </option>
                                      </select>
                                    )}
                                  </Form.Group>
                                  {localStorage.getItem("mode") === "IDF" && (
                                    <Form.Group className="form-group switch-standard">
                                      <Form.Label>
                                        {t("Switch to standard mode")}
                                      </Form.Label>
                                      <div className="asap-grid-toggle">
                                        <input
                                          type="checkbox"
                                          name="switch"
                                          id="switch"
                                        />
                                        <label
                                          for="switch"
                                          onClick={handleChangeMode}
                                        ></label>
                                      </div>
                                    </Form.Group>
                                  )}
                                </>
                              )}
                              {localStorage.getItem("role") === "admin" ||
                              (localStorage.getItem("role") === "doctor" &&
                                doctorData &&
                                doctorData.doctorId) ? (
                                <>
                                  <Form.Group className="form-group">
                                    <Form.Label>
                                      {t("Doctor license")}
                                    </Form.Label>
                                    <input
                                      className="form-control"
                                      name="doctor_license"
                                      type="number"
                                      // value={formValue.phoneNumber}
                                      onChange={handleChange}
                                      id="doctor_license"
                                      defaultValue={
                                        doctorData
                                          ? doctorData.licenceNumber
                                          : ""
                                      }
                                    />
                                  </Form.Group>
                                  <Form.Group className="form-group">
                                    <Form.Label>{t("Title")}</Form.Label>
                                    <select
                                      className="form-control"
                                      name="title"
                                      onChange={handleChange}
                                      defaultValue={
                                        doctorData ? doctorData.title : ""
                                      }
                                    >
                                      <option value="Professor">
                                        {t("Professor")}
                                      </option>
                                      <option value="Doctor">
                                        {t("Doctor")}
                                      </option>
                                    </select>
                                  </Form.Group>
                                  <Form.Group className="form-group">
                                    <Form.Label>
                                      {t("Specilization")}{" "}
                                    </Form.Label>
                                    <Multiselect
                                      className="form-control"
                                      options={consultData}
                                      selectedValues={selectedValue}
                                      onSelect={onSelect}
                                      onRemove={onRemove}
                                      displayValue={
                                        lang === "EN" ? "eng" : "heb"
                                      }
                                      placeholder=""
                                      id="consult"
                                      disable
                                    />
                                  </Form.Group>
                                  <Form.Group className="form-group">
                                    <Form.Label>
                                      {t("Subscriptions")}{" "}
                                    </Form.Label>
                                    <Multiselect
                                      className="form-control"
                                      options={selectedSubscriptions}
                                      selectedValues={selectedSubscriptions}
                                      displayValue={selectedSubscriptions}
                                      disable
                                      isObject={false}
                                    />
                                  </Form.Group>
                                  <Form.Group className="form-group">
                                    <Form.Label>
                                      {t("Patient Type")}{" "}
                                    </Form.Label>
                                    <Multiselect
                                      className="form-control"
                                      options={patienttype}
                                      selectedValues={selectedtype}
                                      onSelect={onSelectType}
                                      onRemove={onRemoveType}
                                      displayValue={
                                        lang === "EN" ? "eng" : "heb"
                                      }
                                      placeholder=""
                                    />
                                  </Form.Group>
                                  {profileDetails?.partnerName ? (
                                    <Form.Group className="form-group">
                                      <Form.Label>{t("Partner")}</Form.Label>
                                      <input
                                        className="form-control"
                                        name="partnerId"
                                        type="text"
                                        id="partnerId"
                                        defaultValue={
                                          profileDetails?.partnerName
                                        }
                                        disabled
                                      />
                                    </Form.Group>
                                  ) : null}
                                  <Form.Group className="form-group">
                                    <Form.Label>
                                      {t("Practice")} / {t("Experience")}
                                    </Form.Label>
                                    <input
                                      className="form-control"
                                      name="experience"
                                      type="text"
                                      // value={formValue.phoneNumber}
                                      onChange={handleChange}
                                      id="experience"
                                      defaultValue={
                                        doctorData ? doctorData.experience : ""
                                      }
                                    />
                                  </Form.Group>
                                  <Form.Group className="form-group">
                                    <Form.Label>{t("Bio")}</Form.Label>
                                    <textarea
                                      className="form-control"
                                      maxlength="300"
                                      name="bio"
                                      type="text"
                                      // value={formValue.phoneNumber}
                                      onChange={handleChange}
                                      id="bio"
                                      defaultValue={
                                        doctorData ? doctorData.bio : ""
                                      }
                                    />
                                  </Form.Group>
                                  <div className="check" id="check">
                                    <input
                                      type="checkbox"
                                      defaultChecked={
                                        doctorData?.isNotification
                                      }
                                      onClick={(e) =>
                                        handleNotification(e.target.checked)
                                      }
                                    />
                                    <span> {t("Notification")}</span>
                                  </div>
                                  {selectedSubscriptions &&
                                    selectedSubscriptions.includes("Rx") && (
                                      <DownloadRxSummary />
                                    )}
                                </>
                              ) : null}
                            </Form>
                            <h2> {t("Address")}</h2>
                            <Form className="form" id="b">
                              <div className="check" id="check">
                                <input
                                  type="checkbox"
                                  defaultChecked={show}
                                  onClick={() => setShow(!show)}
                                />
                                <span> {t("I do not live in Israel")}</span>
                              </div>
                              {show ? (
                                <>
                                  <Form.Group className="form-group">
                                    <Form.Label>{t("Country")} </Form.Label>
                                    <input
                                      className="form-control"
                                      name="country"
                                      type="text"
                                      id="country"
                                      onChange={handleChange}
                                      defaultValue={
                                        profileDetails &&
                                        profileDetails.clientAddress
                                          ? profileDetails?.clientAddress
                                              ?.country
                                          : ""
                                      }
                                    />
                                  </Form.Group>

                                  <Form.Group className="form-group">
                                    <Form.Label>
                                      {t(
                                        "Local phone number (including area code and plus sign +)"
                                      )}
                                    </Form.Label>
                                    <input
                                      className="form-control"
                                      name="localphoneNumber"
                                      type="tel"
                                      // value={formValue.phoneNumber}
                                      onChange={handleChange}
                                      id="localphoneNumber"
                                      defaultValue={
                                        profileDetails &&
                                        profileDetails.clientAddress
                                          ? profileDetails.clientAddress
                                              .localPhone
                                          : ""
                                      }
                                    />
                                  </Form.Group>
                                </>
                              ) : (
                                ""
                              )}

                              <Form.Group className="phone-form">
                                <Form.Label>{t("Street")}</Form.Label>
                                <input
                                  className="form-control"
                                  name="street"
                                  type="text"
                                  // value={formValue.phoneNumber}
                                  onChange={handleChange}
                                  id="street"
                                  defaultValue={
                                    profileDetails &&
                                    profileDetails.clientAddress
                                      ? profileDetails.clientAddress.street
                                      : ""
                                  }
                                />
                              </Form.Group>
                              <Form.Group className="number-form">
                                <Form.Label>{t("House No")}</Form.Label>
                                <input
                                  className="form-control"
                                  name="houseNo"
                                  type="text"
                                  // value={formValue.phoneNumber}
                                  onChange={handleChange}
                                  id="houseNo"
                                  defaultValue={
                                    profileDetails &&
                                    profileDetails.clientAddress
                                      ? profileDetails.clientAddress.house
                                      : ""
                                  }
                                />
                              </Form.Group>

                              <Form.Group className="phone-form">
                                <Form.Label>{t("City")}</Form.Label>
                                <input
                                  className="form-control"
                                  name="city"
                                  type="text"
                                  // value={formValue.phoneNumber}
                                  onChange={handleChange}
                                  id="city"
                                  defaultValue={
                                    profileDetails &&
                                    profileDetails.clientAddress
                                      ? profileDetails.clientAddress.city
                                      : ""
                                  }
                                />
                              </Form.Group>
                              <Form.Group className="number-form">
                                <Form.Label>{t("Postal Code")}</Form.Label>
                                <input
                                  className="form-control"
                                  name="postalcode"
                                  type="tel"
                                  // value={formValue.phoneNumber}
                                  onChange={handleChange}
                                  id="postalcode"
                                  dir="auto"
                                  defaultValue={
                                    profileDetails &&
                                    profileDetails.clientAddress
                                      ? profileDetails.clientAddress.pincode
                                      : ""
                                  }
                                />
                              </Form.Group>
                              {localStorage.getItem("role") === "doctor" && (
                                <>
                                  <Form.Group className="phone-form">
                                    <Form.Label>
                                      {t(
                                        "E-mail for calendar invitations (If different from the main e-mail address)"
                                      )}
                                    </Form.Label>
                                    <input
                                      className="form-control"
                                      name="calendarEmail"
                                      type="tel"
                                      // value={formValue.phoneNumber}
                                      onChange={(e) =>
                                        setCalendarEmail(e.target.value)
                                      }
                                      id="postalcode"
                                      dir="auto"
                                      defaultValue={
                                        profileDetails &&
                                        profileDetails.doctorData[0]
                                          ?.calendarEmail
                                          ? profileDetails.doctorData[0]
                                              ?.calendarEmail
                                          : ""
                                      }
                                    />
                                  </Form.Group>
                                  <div className="check">
                                    <input
                                      type="checkbox"
                                      checked={calenderInvite}
                                      onClick={() =>
                                        setCalenderInvite(!calenderInvite)
                                      }
                                    />
                                    <span>
                                      {" "}
                                      {t(
                                        "Get calendar invitations for meetings"
                                      )}
                                    </span>
                                  </div>
                                </>
                              )}
                              {localStorage.getItem("role") === "client" && (
                                <>
                                  <div className="check">
                                    <input
                                      type="checkbox"
                                      checked={consent}
                                      onClick={() => setConsent(!consent)}
                                    />
                                    <span>
                                      {" "}
                                      {t("I agree to the insurance consent")}
                                    </span>
                                  </div>
                                  <div className="check">
                                    <input
                                      type="checkbox"
                                      checked={terms}
                                      onClick={() => setTerms(!terms)}
                                    />
                                    <span>
                                      {" "}
                                      {t("I agree to the site term's of use")}
                                    </span>
                                  </div>
                                </>
                              )}

                              <Button
                                type="submit"
                                onClick={AddressHandleSubmit}
                              >
                                {" "}
                                {t("Submit")}
                              </Button>
                            </Form>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {role !== "admin" && (
              <div className="row">
                <div className="col-md-12 textdiv">
                  <h5>
                    {" "}
                    {t(
                      "Information that you have provided and will provide, at your will and with your consent, will be kept by usand may be used by Haydock from Beit Valor for direct mailing,data analysis and the provision of services. The information willbe transferred to business partners, and suppliers for the aforementioned needs"
                    )}
                  </h5>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Profile;
