import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "../../Login/login.css";
// import crs from "../../assets/images/cross/crs.png"
import { useTranslation } from "react-i18next";
import OtpInput from "react-otp-input";
import { Grid, Paper, Typography, Modal } from "@material-ui/core";
import { toast } from "react-toastify";
import { userLogin, verifyOtp } from "../../../config/service";
import { Form, Button } from "react-bootstrap";
import { handleKeyPress } from "../../Login/login";
import jwt_decode from "jwt-decode";

const AdminLogin = () => {
  const { t } = useTranslation();

  const navigate = useNavigate();
  const [bisablebutton, setDisableButton] = useState(false);
  const [formValue, setformValue] = React.useState({
    id_number: "",
    mobile: "",
    password: "",
  });
  const [idError, setIdError] = useState("");
  const [mobileError, setmobileError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [otp, setOtp] = useState("");
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = useState(false);
  const [seconds, setSeconds] = useState(60);
  const [isResendDisable, setIsResendDisable] = useState(true);
  const [channel, setChannel] = useState("whatsapp");
  const [resendLoading, setResendLoading] = useState(false);

    const handleLogin = async (channelValue) => {
      let data = {
        phone: formValue.mobile,
        id: formValue.id_number,
        password: formValue.password,
        key: "admin",
        channel: channelValue,
      };

      if (validate()) {
        setLoading(true);
        setResendLoading(true);
        try {
          // make axios post request
          const response = await userLogin(data);
          if (response.data.msg === ("Success" || "הצלחה")) {
            toast.success(t("An OTP sent to your mobile number"));
            setOpen(true);
          } else {
            response.data.message
              ? toast.error(t(response.data.message))
              : toast.error(t(response.data.msg));
          }
          setLoading(false);
        } catch (error) {
          console.error(error);
        } finally {
          setLoading(false);
          setResendLoading(false);
        }
      }
    };

    const handleSubmit = (e) => {
      e.preventDefault();
      handleLogin(channel);
    };

    const handleResendOtp = (value) => {
      setChannel(value);
      handleLogin(value);
    };
    const OtpValidation = async (e) => {
      e.preventDefault();
      setDisableButton(true);
      // store the states in the form data
      let data = {
        phone: formValue.mobile,
        otp: otp,
        id: formValue.id_number,
        role: "admin",
      };

    if (validate()) {
      try {
        // make axios post request
        const response = await verifyOtp(data);
        console.log("OTP Data", response);
        if ([200, 201, 203].includes(response.data.code)) {
          localStorage.setItem("token", response.data.token);
          localStorage.setItem("refreshToken", response.data.refreshToken);
          const decodedToken = jwt_decode(response.data.token);
          localStorage.setItem("userName", decodedToken.name);
          localStorage.setItem("userId", decodedToken.id);
          localStorage.setItem("role", decodedToken.role);
          toast.success(t(response.data.msg));
          navigate("/AdminDashboard");
          location.reload();
          setOpen(false);
          setDisableButton(false);
        } else {
          toast.error(t(response.data.msg));
        }
      } catch (error) {
        setOtp();
        setDisableButton(false);
        console.log(error);
        //   setErrorMsg(true);
      }
    }
  };

  const handleChange = (event) => {
    setformValue({
      ...formValue,
      [event.target.name]: event.target.value,
    });
  };

  const validate = () => {
    let isValid = true;

    if (!formValue.id_number) {
      isValid = false;
      setIdError(t("Please Enter Id Number"));
    } else {
      setIdError("");
    }
    if (!formValue.password) {
      isValid = false;
      setPasswordError(t("Please Enter Password"));
    } else {
      setPasswordError("");
    }

    if (!formValue.mobile) {
      isValid = false;

      setmobileError(t("Please enter your phone number"));
    }

    if (typeof formValue.mobile !== "undefined") {
      var pattern = new RegExp(/^[0-9\b]+$/);

      if (!pattern.test(formValue.mobile)) {
        isValid = false;

        setmobileError(t("Please enter only number"));
      } else if (formValue.mobile.length != 10) {
        isValid = false;

        setmobileError(t("Please enter valid phone number"));
      }
    }

    return isValid;
  };

  useEffect(() => {
    if (open) {
      const timer = setInterval(() => {
        setSeconds((prevSeconds) => {
          if (prevSeconds === 1) {
            clearInterval(timer);
            setIsResendDisable(false);
          }
          return prevSeconds - 1;
        });
      }, 1000);

      return () => clearInterval(timer);
    }
  }, [open]);

  return (
    <div className="auth-page">
      <div className="newRegd">
        <div>
          <div className="signTop">
            {/* <img src={crs} alt="" onClick={() => navigate("/")} /> */}
            <h2>{t("Connection")}</h2>
          </div>

          <Form onSubmit={handleSubmit}>
            <Form.Group className="form-group ">
              <input
                className="form-control id_container"
                name="id_number"
                type="number"
                onChange={handleChange}
                id="id_input"
                onWheel={(e) => e.target.blur()}
                placeholder={t("ID Number")}
                onKeyPress={handleKeyPress}
                onInput={(e) => (e.target.value = e.target.value.slice(0, 14))}
              />
            </Form.Group>

            <span style={{ color: "red", textAlign: "center" }}>
              {t(`${idError}`)}
            </span>
            <Form.Group className="form-group ">
              <input
                className="form-control phone_number id_container"
                name="mobile"
                type="number"
                onWheel={(e) => e.target.blur()}
                onChange={handleChange}
                id="mobile"
                placeholder={t("Mobile Number")}
              />
            </Form.Group>
            <span style={{ color: "red", textAlign: "center" }}>
              {t(`${mobileError}`)}
            </span>

            <Form.Group className="form-group ">
              <input
                className="form-control"
                name="password"
                type="password"
                onChange={handleChange}
                id="password"
                placeholder={t("Enter Password")}
              />
            </Form.Group>
            <span style={{ color: "red", textAlign: "center" }}>
              {t(`${passwordError}`)}
            </span>

            <Button type="submit" onClick={handleSubmit} disabled={loading}>
              {t("Connect")}
            </Button>
          </Form>
          <div className="google-link">
            <h6>
              {" "}
              This site is protected by reCAPTCHA and the Google{" "}
              <a href="https://policies.google.com/privacy">
                Privacy Policy
              </a>{" "}
              and{" "}
              <a href="https://policies.google.com/terms">Terms of Service</a>{" "}
              apply.
            </h6>
          </div>

          <div className="hlink">
            <a href="">{t("Forget Password")}</a>
          </div>

          <div>
            <Modal
              open={open}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
              style={{ marginTop: "10%" }}
            >
              <Form onSubmit={OtpValidation}>
                <Grid container direction="column" alignItems="center">
                  <Grid item xs>
                    <Paper className="paper" square>
                      <Grid container direction="column" alignItems="center">
                        <Grid item lg>
                          <Typography variant="h4" gutterBottom>
                            {t("Please Enter the OTP to Verify Your Account")}
                          </Typography>
                        </Grid>
                        <Grid item sm>
                          <Typography variant="h6" gutterBottom>
                            <span>{formValue.mobile} </span>
                            {t("An OTP(one time password) has been sent to")}
                          </Typography>
                        </Grid>

                        <Grid item xs>
                          <OtpInput
                            value={otp}
                            onChange={(otp) => {
                              setOtp(otp);
                            }}
                            shouldAutoFocus={true}
                            numInputs={6}
                            inputStyle={{
                              fontSize: "24px",
                              width: "30px",
                              height: "30px",
                              margin: "4px",
                              borderTop: "0px",
                              borderLeft: "0px",
                              borderRight: "0px",
                              outline: "none",
                              borderColor: "#000a46",
                            }}
                            containerStyle={{
                              margin: "20px auto",
                              padding: "10px",
                            }}
                            isInputNum
                            className="otp_number"
                            whenEntered={true}
                          />
                        </Grid>
                        <Grid container direction="column" alignItems="center">
                          <Grid
                            item
                            xs
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              margin: "10px",
                            }}
                          >
                            <Button
                              disabled={bisablebutton}
                              type="submit"
                              onClick={OtpValidation}
                              className="submit"
                              style={{ maxWidth: "190px", margin: "0px auto" }}
                            >
                              {t("Validate OTP")}
                            </Button>
                            <Grid
                              item
                              sm
                              style={{
                                padding: "15px 0px",
                                margin: "0px auto",
                              }}
                            >
                              <Typography variant="h6">
                                {isResendDisable ? (
                                  <>
                                    <span>{seconds}</span>
                                  </>
                                ) : (
                                  t("")
                                )}
                              </Typography>
                            </Grid>
                            <div className="resend-btn-grid">
                              <Button
                                className="submit"
                                onClick={() => handleResendOtp("whatsapp")}
                                disabled={isResendDisable || resendLoading}
                              >
                                {t("Resend OTP on WhatsApp")}
                              </Button>
                              <Button
                                className="submit"
                                onClick={() => handleResendOtp("sms")}
                                disabled={isResendDisable || resendLoading}
                              >
                                {t("Resend OTP via SMS")}
                              </Button>
                            </div>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Paper>
                  </Grid>
                </Grid>
              </Form>
            </Modal>
          </div>
        </div>
      </div>
    </div>
  );
};
export default AdminLogin;
