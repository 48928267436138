import React, { useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "../Dashborad/client.css";
import { Button } from "react-bootstrap";
import OfferDate from "./OfferDate";
import moment from "moment";
import { useTranslation } from "react-i18next";
// import { filterdata } from "../Dashborad/ClientMyRequest";
let index;
const filterdata = (datearray, data, i) => {
  if (datearray.includes(moment(data).format("DD.MM.YYYY"))) {
    return true;
  } else {
    index = i;
    datearray.push(moment(data).format("DD.MM.YYYY"));
    return false;
  }
};

const DoctorPending = (props) => {
  const { t } = useTranslation();
  const [id, setId] = useState();
  const [key, setKey] = useState("new");
  const lang = localStorage.getItem("lang");
  let datafullscreen = [];
  let datasmallscreen = [];

  const OfferView = (e, data, Id) => {
    e.preventDefault();
    props.setNewOfferDate(data);
    props.setOfferModal(true);
    setId(Id);
  };

  const asssonpossible = (e, Id) => {
    e.preventDefault();
    props.setOfferModal(true);
    setId(Id);
  };

  const weekday = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];

  const shift = (d) => {
    let shifttime = "";
    if (["8", "9", "10", "11"].includes(d)) {
      shifttime = t("Morning");
    }
    if (["12", "13", "14", "15"].includes(d)) {
      shifttime = t("Afternoon");
    }
    if (["16", "17", "18", "19", "20", "21", "22"].includes(d)) {
      shifttime = t("Evening");
    }

    return shifttime;
  };

  return (
    <div>
      <OfferDate
        newOfferDate={props.newOfferDate}
        offerModal={props.offerModal}
        handleClose={props.handleClose}
        id={id}
        fdate={props.fdate}
        setFDate={props.setFDate}
        sdate={props.sdate}
        setSDate={props.setSDate}
        ftime={props.ftime}
        setFTime={props.setFTime}
        stime={props.stime}
        setSTime={props.setSTime}
        startDate={props.startDate}
        setStartDate={props.setStartDate}
        startDate1={props.startDate1}
        setStartDate1={props.setStartDate1}
      />
      <div className="toggletab">
        {key === "new" ? (
          <section className="userWrapper">
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <div className="userInnerWrapper userInnerWrapB">
                    {props.loader ? (
                      <h2>{t("Please wait")} ...</h2>
                    ) : props?.allAppointment?.length > 0 ? (
                      <ul>
                        <>
                          <div className="toggleDiv">
                            <span
                              onClick={() => setKey("new")}
                              className={
                                key == "new" ? "spanActive" : "toggle "
                              }
                            >
                              {t("New Requests")}
                            </span>
                            <span
                              onClick={() => setKey("pending")}
                              className={
                                key == "pending" ? "spanActive" : "toggle "
                              }
                            >
                              {t("Pending Requests")}
                            </span>
                          </div>
                          <div className="queuesLabel">
                            <h1>{t("New Requests")}</h1>
                          </div>
                          {props?.allAppointment &&
                            props?.allAppointment?.map((data, i) => {
                              return (
                                <li key={i}>
                                  <div className="cfBase1">
                                    <div className="cfFirst1">
                                      <div className="cfImgbg">
                                        <div className="adult">
                                          <h3>{t("Patient")}</h3>
                                          <h2>
                                            {data?.patientDetails
                                              ? data?.patientDetails.first_name
                                              : ""}{" "}
                                            {data?.patientDetails
                                              ? data?.patientDetails.last_name
                                              : ""}
                                          </h2>

                                        <h3>{t("Gender")}</h3>
                                        <h2>
                                          {" "}
                                          {data?.patientDetails
                                            ? t(
                                                data?.patientDetails
                                                  ?.patientGender
                                              )
                                            : ""}{" "}
                                        </h2>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="adult">
                                    <h3>{t("Main Complaint1")}</h3>
                                    <h2>{data?.requestDescription} </h2>
                                    {data?.isFollowUp ? (
                                      <h4 className="followup-text">
                                        {t("Follow Up Meeting")}
                                      </h4>
                                    ) : null}
                                  </div>
                                  <div className="requestdate">
                                    <div className="datebox">
                                      <div>
                                        {!data.isUrgent ? (
                                          data.requestedDates.map((d) => {
                                            return filterdata(
                                              index >= i
                                                ? datasmallscreen
                                                : (datasmallscreen = []),
                                              d,
                                              i
                                            ) ? (
                                              <>
                                                <span className="datetimeshift">
                                                  {" "}
                                                  {t(
                                                    shift(moment(d).format("H"))
                                                  )}{" "}
                                                </span>{" "}
                                              </>
                                            ) : (
                                              <>
                                                <br></br>
                                                <span className="datetimeshift">{` ${moment(
                                                  d
                                                ).format("DD.MM.YYYY")} ${t(
                                                  shift(moment(d).format("H"))
                                                )}`}</span>
                                              </>
                                            );
                                          })
                                        ) : (
                                          <h2 className="datetimeshift">
                                            {" "}
                                            {t("ASAP")}
                                          </h2>
                                        )}
                                      </div>
                                    </div>
                                    <div className="offer">
                                      <div>
                                        {data.requestedDates?.length != 0 &&
                                        !data.ASAP ? (
                                          <Button
                                            size="sm"
                                            className="offer_button"
                                            onClick={(e) =>
                                              OfferView(
                                                e,
                                                data.requestedDates,
                                                data._id
                                              )
                                            }
                                          >
                                            {" "}
                                            {t("Offer Date")}
                                          </Button>
                                        ) : (
                                          <Button
                                            className="offer_button"
                                            onClick={(e) =>
                                              OfferView(e, [], data._id)
                                            }
                                          >
                                            {" "}
                                            {t("Offer Date")}
                                          </Button>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </li>
                            );
                          })}
                        </>
                      </ul>
                    ) : (
                      <>
                        <div className="toggleDiv">
                          <span
                            onClick={() => setKey("new")}
                            className={key == "new" ? "spanActive" : "toggle "}
                          >
                            {t("New Requests")}
                          </span>
                          <span
                            onClick={() => setKey("pending")}
                            className={
                              key == "pending" ? "spanActive" : "toggle "
                            }
                          >
                            {t("Pending Requests")}
                          </span>
                        </div>
                        <h3 className="no_meeting">
                          {t("You have no new requests")}
                        </h3>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </section>
        ) : (
          <section className="userWrapper">
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <div className="userInnerWrapper userInnerWrapB">
                    {props.loader ? (
                      <h2></h2>
                    ) : props.dateoffer.length != 0 ? (
                      <ul>
                        <>
                          <div className="toggleDiv">
                            <span
                              onClick={() => setKey("new")}
                              className={
                                key == "new" ? "spanActive" : "toggle "
                              }
                            >
                              {t("New Requests")}
                            </span>
                            <span
                              onClick={() => setKey("pending")}
                              className={
                                key == "pending" ? "spanActive" : "toggle "
                              }
                            >
                              {t("Pending Requests")}
                            </span>
                          </div>
                          <div className="queuesLabel">
                            <h1>{t("Pending Requests")}</h1>
                          </div>
                          {props?.dateoffer &&
                            props?.dateoffer?.map((data, i) => {
                              return (
                                <li key={i}>
                                  <div className="cfBase1">
                                    <div className="cfFirst1">
                                      <div className="cfImgbg">
                                        <div className="adult">
                                          <h3>{t("Patient")}</h3>
                                          <h2>
                                            {data?.patientDetails
                                              ? data?.patientDetails.first_name
                                              : ""}{" "}
                                            {data?.patientDetails
                                              ? data?.patientDetails.last_name
                                              : ""}
                                          </h2>

                                        <h3>{t("Gender")}</h3>
                                        <h2>
                                          {data?.patientDetails
                                            ? t(
                                                data?.patientDetails
                                                  ?.patientGender
                                              )
                                            : ""}{" "}
                                        </h2>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="adult">
                                    <h3>{t("Main Complaint")}</h3>
                                    <h2>{data?.requestDescription} </h2>
                                    {data?.isFollowUp ? (
                                      <h4 className="followup-text">
                                        {t("Follow Up Meeting")}
                                      </h4>
                                    ) : null}
                                  </div>
                                  <div className="requestdate offerMeeting">
                                    <div className="cfInfo datebox">
                                      <div>
                                        {data.offerDates[0]?.offeredDates
                                          .length != 0 ? (
                                          data.offerDates[0]?.offeredDates.map(
                                            (d, index) => {
                                              return (
                                                <div
                                                  className="scheduleDate1"
                                                  key={index}
                                                >
                                                  <div
                                                    className={`${
                                                      lang === "HE"
                                                        ? "showdayHeb"
                                                        : "showdayEng"
                                                    }`}
                                                  >
                                                    <span>
                                                      {t(
                                                        weekday[
                                                          new Date(d).getDay()
                                                        ]
                                                      )}
                                                    </span>
                                                  </div>
                                                  <div>
                                                    <span className="dayhours">
                                                      {" "}
                                                      {moment(d).format(
                                                        "DD.MM.YYYY"
                                                      )}
                                                    </span>
                                                    <span className="dayhours">
                                                      {moment(d).format("H:mm")}
                                                    </span>
                                                  </div>
                                                </div>
                                              );
                                            }
                                          )
                                        ) : (
                                          <span
                                            className="dayhours"
                                            style={{ padding: "0 40px" }}
                                          >
                                            {t("Requested date not selected")}{" "}
                                          </span>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </li>
                            );
                          })}
                        </>
                      </ul>
                    ) : (
                      <>
                        <div className="toggleDiv">
                          <span
                            onClick={() => setKey("new")}
                            className={key == "new" ? "spanActive" : "toggle "}
                          >
                            {t("New Requests")}
                          </span>
                          <span
                            onClick={() => setKey("pending")}
                            className={
                              key == "pending" ? "spanActive" : "toggle "
                            }
                          >
                            {t("Pending Requests")}
                          </span>
                        </div>
                        <h3 className="no_meeting">
                          {t("You have no new requests")}
                        </h3>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </section>
        )}
      </div>
      <div className="toggleContent">
        <section className="userWrapper">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="userInnerWrapper userInnerWrapB">
                  {key === "new" ? (
                    props.loader ? (
                      <h2>{t("Please wait")} ...</h2>
                    ) : props.allAppointment.length != 0 ? (
                      <ul>
                        <>
                          <div className="toggleDiv">
                            <span
                              onClick={() => setKey("new")}
                              className={
                                key == "new" ? "spanActive" : "toggle "
                              }
                            >
                              {t("New Requests")}
                            </span>
                            <span
                              onClick={() => setKey("pending")}
                              className={
                                key == "pending" ? "spanActive" : "toggle "
                              }
                            >
                              {t("Pending Requests")}
                            </span>
                          </div>
                          <h1 className="requestsLabel">{t("New Requests")}</h1>
                          {props?.allAppointment?.length > 0 &&
                            props?.allAppointment?.map((data, i) => {
                              return (
                                <li key={i}>
                                  <div className="cfBase1">
                                    <div className="cfFirst1 sortshiftwidth">
                                      <div className="cfImgbg">
                                        <div className="cfImgTxt">
                                          <h3>{t("Patient")}</h3>
                                          <h2>
                                            {data?.patientDetails
                                              ? data?.patientDetails.first_name
                                              : ""}{" "}
                                            {data?.patientDetails
                                              ? data?.patientDetails.last_name
                                              : ""}
                                          </h2>

                                          <h3>{t("Gender")}</h3>
                                          <h2>
                                            {" "}
                                            {data?.patientDetails
                                              ? t(
                                                  data?.patientDetails
                                                    ?.patientGender
                                                )
                                              : ""}{" "}
                                          </h2>

                                          <h3>{t("Requested Times")}</h3>
                                          {!data.isUrgent ? (
                                            data.requestedDates
                                              .sort()
                                              .map((d) => {
                                                return filterdata(
                                                  index >= i
                                                    ? datafullscreen
                                                    : (datafullscreen = []),
                                                  d,
                                                  i
                                                ) ? (
                                                  <>
                                                    <span className="datetimeshift">
                                                      {" "}
                                                      {t(
                                                        shift(
                                                          moment(d).format("H")
                                                        )
                                                      )}{" "}
                                                    </span>{" "}
                                                  </>
                                                ) : (
                                                  <>
                                                    <br></br>
                                                    <span className="datetimeshift">{` ${moment(
                                                      d
                                                    ).format("DD.MM.YYYY")} ${t(
                                                      shift(
                                                        moment(d).format("H")
                                                      )
                                                    )}`}</span>
                                                  </>
                                                );
                                              })
                                          ) : (
                                            <h2 className="datetimeshift">
                                              {" "}
                                              {t("ASAP")}
                                            </h2>
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                    <div className="cfDate maincomplaint">
                                      <h3>{t("Main Complaint")}</h3>
                                      <h2>{data?.requestDescription} </h2>
                                      {data?.isFollowUp ? (
                                        <h4 className="followup-text">
                                          {t("Follow Up Meeting")}
                                        </h4>
                                      ) : null}
                                    </div>
                                    <div className="requestdate">
                                      <div className="datebox">
                                        <div>
                                          {data.requestedDates?.length != 0 ? (
                                            data.requestedDates?.map(
                                              (d, index2) => {
                                                return (
                                                  <div
                                                    className="scheduleDate1"
                                                    key={index2}
                                                  >
                                                    <div
                                                      className={`${
                                                        lang === "HE"
                                                          ? "showdayHeb"
                                                          : "showdayEng"
                                                      }`}
                                                    >
                                                      <span>
                                                        {t(
                                                          weekday[
                                                            new Date(d).getDay()
                                                          ]
                                                        )}
                                                      </span>
                                                    </div>
                                                    <div>
                                                      <span className="dayhours">
                                                        {" "}
                                                        {moment(d).format(
                                                          "DD.MM.YYYY"
                                                        )}
                                                      </span>
                                                      <span className="dayhours">
                                                        {data?.ASAP
                                                          ? null
                                                          : shift(
                                                              moment(d).format(
                                                                "H"
                                                              )
                                                            )}
                                                      </span>
                                                    </div>
                                                  </div>
                                                );
                                              }
                                            )
                                          ) : (
                                            <span
                                              className="dayhours"
                                              style={{ padding: "0 40px" }}
                                            >
                                              {t("Requested date not selected")}{" "}
                                            </span>
                                          )}
                                        </div>
                                      </div>
                                      <div className="offer">
                                        <div>
                                          {data.requestedDates?.length != 0 &&
                                          !data.ASAP ? (
                                            <Button
                                              size="sm"
                                              className="offer_button"
                                              onClick={(e) =>
                                                OfferView(
                                                  e,
                                                  data.requestedDates,
                                                  data._id
                                                )
                                              }
                                            >
                                              {" "}
                                              {t("Offer Date")}
                                            </Button>
                                          ) : (
                                            <Button
                                              className="offer_button"
                                              onClick={(e) =>
                                                asssonpossible(e, data._id)
                                              }
                                            >
                                              {" "}
                                              {t("Offer Date")}
                                            </Button>
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </li>
                              );
                            })}
                        </>
                      </ul>
                    ) : (
                      <>
                        <div className="toggleDiv">
                          <span
                            onClick={() => setKey("new")}
                            className={key == "new" ? "spanActive" : "toggle "}
                          >
                            {t("New Requests")}
                          </span>
                          <span
                            onClick={() => setKey("pending")}
                            className={
                              key == "pending" ? "spanActive" : "toggle "
                            }
                          >
                            {t("Pending Requests")}
                          </span>
                        </div>
                        <h3 className="no_meeting">
                          {t("You have no new requests")}
                        </h3>
                      </>
                    )
                  ) : props.loader ? (
                    <h2></h2>
                  ) : props.dateoffer.length != 0 ? (
                    <ul>
                      <>
                        <div className="toggleDiv">
                          <span
                            onClick={() => setKey("new")}
                            className={key == "new" ? "spanActive" : "toggle "}
                          >
                            {t("New Requests")}
                          </span>
                          <span
                            onClick={() => setKey("pending")}
                            className={
                              key == "pending" ? "spanActive" : "toggle "
                            }
                          >
                            {t("Pending Requests")}
                          </span>
                        </div>
                        <h1>{t("Pending Requests")}</h1>
                        {props?.dateoffer &&props.dateoffer.map((data, i) => {
                          return (
                            <li key={i}>
                              <div className="cfBase1">
                                <div className="cfFirst1">
                                  <div className="cfImgbg">
                                    <div className="cfImgTxt">
                                      <h3>{t("Patient")}</h3>
                                      <h2>
                                        {data?.patientDetails
                                          ? data?.patientDetails.first_name
                                          : ""}{" "}
                                        {data?.patientDetails
                                          ? data?.patientDetails.last_name
                                          : ""}
                                      </h2>
                                      <h4>
                                        {t("Gender")}-
                                        {data?.patientDetails
                                          ? t(
                                              data?.patientDetails
                                                ?.patientGender
                                            )
                                          : ""}{" "}
                                      </h4>
                                    </div>
                                  </div>
                                </div>
                                <div className="cfDate maincomplaint">
                                  <h3>{t("Main Complaint")}</h3>
                                  <h2>{data?.requestDescription} </h2>
                                  {data?.isFollowUp ? (
                                    <h4 className="followup-text">
                                      {t("Follow Up Meeting")}
                                    </h4>
                                  ) : null}
                                </div>
                                <div className="requestdate offerMeeting">
                                  <div className="cfInfo datebox">
                                    <div>
                                      {data.offerDates[0]?.offeredDates
                                        .length != 0 ? (
                                        data.offerDates[0]?.offeredDates.map(
                                          (d, index2) => {
                                            return (
                                              <div
                                                className="scheduleDate1"
                                                key={index2}
                                              >
                                                <div
                                                  className={`${
                                                    lang === "HE"
                                                      ? "showdayHeb"
                                                      : "showdayEng"
                                                  }`}
                                                >
                                                  <span>
                                                    {t(
                                                      weekday[
                                                        new Date(d).getDay()
                                                      ]
                                                    )}
                                                  </span>
                                                </div>
                                                <div>
                                                  <span className="dayhours">
                                                    {" "}
                                                    {moment(d).format(
                                                      "DD.MM.YYYY"
                                                    )}
                                                  </span>
                                                  <span className="dayhours">
                                                    {moment(d).format("H:mm")}
                                                  </span>
                                                </div>
                                              </div>
                                            );
                                          }
                                        )
                                      ) : (
                                        <span
                                          className="dayhours"
                                          style={{ padding: "0 40px" }}
                                        >
                                          {t("Requested date not selected")}{" "}
                                        </span>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </li>
                          );
                        })}
                      </>
                    </ul>
                  ) : (
                    <>
                      <div className="toggleDiv">
                        <span
                          onClick={() => setKey("new")}
                          className={key == "new" ? "spanActive" : "toggle "}
                        >
                          {t("New Requests")}
                        </span>
                        <span
                          onClick={() => setKey("pending")}
                          className={
                            key == "pending" ? "spanActive" : "toggle "
                          }
                        >
                          {t("Pending Requests")}
                        </span>
                      </div>
                      <h3 className="no_meeting">
                        {t("You have no new requests")}
                      </h3>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};
export default DoctorPending;
